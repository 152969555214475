import * as React from "react";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function InfoPanel(props) {
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.toggle(false);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {props.header}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        {!props.info[0]
          ? "Loading"
          : Object.keys(props.info).map((e, i) => {
              if (props.info[e].text.length > 1) {
                return Object.keys(props.info[e].text).map((k, j) => {
                  return (
                    <Typography gutterBottom>
                      <ArrowRightIcon fontSize="small" />
                      <span className="bullet-text">
                        {props.info[e].text[k]}
                      </span>
                    </Typography>
                  );
                });
              } else {
                return (
                  <Typography gutterBottom>{props.info[e].text[0]}</Typography>
                );
              }
            })}
      </DialogContent>
    </BootstrapDialog>
  );
}
