import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";
import CustomizedSteppers from "./components/OrderProgressBar";
import Grid from "@mui/material/Grid";
import OrderDetailsBlock from "./components/OrderDetailSection";
import { Box } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getStepperStatus(data) {
  if (data["Customer Approval Status Flag"] !== "Completed") {
    return 0;
  } else if (data["Production Status Flag"] === "Inactive") {
    return 0;
  } else if (data["Production Status Flag"] === "Started") {
    return 1;
  } else if (data["Shipping Status Flag"] !== "Completed") {
    return 2;
  } else if (data["Shipping Status Flag"] === "Completed") {
    return 3;
  } else {
    return 0;
  }
}

export default function OrderSearch(params) {
  const dataRenderMap = {
    sectionOne: {
      header: "Order Details",
      rows: {
        "Customer Name": {
          super: 1,
          name: "Customer Name",
          details: [
            {
              text: ["N/A"],
            },
          ],
        },
        Destination: {
          super: 2,
          name: "Shipping Destination",
          details: [
            {
              text: [
                "This is the province/state we are shipping your final order to.",
              ],
            },
          ],
        },
        "Payment Status": {
          super: 3,
          name: "Payment Status",
          details: [
            {
              text: [
                "This is the percentage of your order that has been paid.",
              ],
            },
            {
              text: [
                "Here is the minimum payment required at each stage of production:",
              ],
            },
            {
              text: [
                "To begin artwork: $50 deposit (credited towards your final order)",
                "To begin production: 50% of total order value",
                "To ship your order: 100% of order value",
              ],
            },
            {
              text: [
                'If you see "Other" as your value your payment status has some sort of exception. Typically, this value means you paid a portion of your order, then made changes after making payment that changed the total order value.',
              ],
            },
          ],
        },
      },
    },
    sectionTwo: {
      header: "Customer Approval Status",
      rows: {
        "Artwork Status": {
          super: 4,
          name: "Artwork Status",
          details: [
            {
              text: [
                "This is the stage your artwork is at. We require artwork approval prior to assigning a space in our production queue. ",
              ],
            },
            {
              text: [
                "Processing - The JerseysMadeEasy.com team is working on creating or revising your artwork",
                "Awaiting Approval - The JerseysMadeEasy.com team has sent artwork to you and is awaiting your feedback",
                "Approved - You have given the JerseysMadeEasy.com team approval to begin production",
              ],
            },
            {
              text: [
                "Once orders have been approved changes may or may not be possible depending on where your order is in the production queue. Order changes may also result in updated/extended ETAs and/or increased costs, depending on the nature and timing of your request.",
              ],
            },
          ],
        },
        Sample: {
          super: 5,
          name: "Sample Approval",
          details: [
            {
              text: [
                "Some products allow for a sample printout/sew out for approval in addition to on-screen artwork approvals. If applicable, these samples typically come 1-2 weeks after artwork approval.",
              ],
            },
            {
              text: [
                "Awaiting Sample - Either your artwork is waiting to be approved or, if it is approved, the JerseysMadeEasy.com team is working on creating your sample ",
                "Awaiting Approval - The JerseysMadeEasy.com team has sent your sample to you and is awaiting ",
                "Approved - You have given the JerseysMadeEasy.com team approval to begin production",
              ],
            },
            {
              text: [
                "Once orders have been approved changes may or may not be possible depending on where your order is in the production queue. Order changes may also result in updated/extended ETAs and/or increased costs, depending on the nature and timing of your request.",
              ],
            },
          ],
        },
      },
    },
    sectionThree: {
      header: "Production Status",
      rows: {
        "Production Status": {
          super: 6,
          name: "Production Status",
          details: [
            {
              text: ["Production begins once you have BOTH:"],
            },
            {
              text: [
                "Approved artwork (and sample, if applicable)",
                "Paid at least 50% of your order value",
              ],
            },
            {
              text: [
                "Once orders have been approved changes may or may not be possible depending on where your order is in the production queue. Order changes may also result in updated/extended ETAs and/or increased costs, depending on the nature and timing of your request.",
              ],
            },
          ],
        },
        "Shipping Date": {
          super: 7,
          name: "Completion Date",
          details: [
            {
              text: [
                "Estimated completion date of your order.  We will follow up on or prior to this date to collect final payment if you have a balance outstanding. Expect courier tracking the following day if your order is paid in full by this date.",
              ],
            },
          ],
        },
      },
    },
    sectionFour: {
      header: "Shipping Status",
      rows: {
        "Shipping Status": {
          super: 8,
          name: "Shipping Status",
          details: [
            {
              text: [
                "If your order is shipped you should have received a courier tracking code from JerseysMadeEasy.com. Please check your spam if you cannot find it.",
              ],
            },
          ],
        },
        ETA: {
          super: 9,
          name: "Estimated Arrival Date",
          details: [
            {
              text: [
                "Estimated arrival date is the date JerseysMadeEasy.com expects your order to be at your doorstep based on typical transit times to your province. Some rural locations may take an additional day and remote locations may take several extra days. Your courier tracking code will provide a more accurate ETA once you receive it. Estimated arrival dates may experience delays if there is a delay in final order payment.",
              ],
            },
          ],
        },
        "Drop Dead Date ETA": {
          super: 10,
          name: 'Customer "Drop Dead" Date',
          details: [
            {
              text: [
                'Some customers have communicated to us that they have a specific one-time event or other form of "hard deadline". If your order says "N/A" we have not received instruction of a "hard deadline".',
              ],
            },
          ],
        },
      },
    },
  };

  const [orderId, changeOrderId] = useState(params.order);
  const [orderEmail, changeOrderEmail] = useState("");

  const [open, setOpen] = React.useState(false);
  const [data, updateData] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getOrder = () => {
    var id = orderId;
    var email = orderEmail;
    if (orderId === undefined || orderId === "") id = -1;
    if (orderEmail === undefined || orderEmail === "") email = "null";

    fetch(
      "https://us-west1-civil-clarity-334620.cloudfunctions.net/order-portal-backend/airtable/order?orderId=" +
        id +
        "&email=" +
        email
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.records.length <= 0) handleOpen();
        else {
          updateData(result.records);
        }
      });
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    getOrder();
    // send to server with e.g. `window.fetch`
  };

  useEffect(() => {
    if (params.order != null) getOrder();
  });

  return (
    <div>
      <div className="search-card">
        <form onSubmit={onFormSubmit}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                className="search-instructions"
              >
                Enter your order number and your email to look up the details
              </Typography>
              <div className="search-input">
                <TextField
                  id="outlined-basic"
                  label="Order Number"
                  variant="outlined"
                  className="full-width"
                  onChange={(event) => changeOrderId(event.target.value)}
                />
                <Box sx={{ height: 20 }} />
                <TextField
                  id="order-email-basic"
                  label="Order Email"
                  variant="outlined"
                  className="full-width"
                  onChange={(event) => changeOrderEmail(event.target.value)}
                />
              </div>
            </CardContent>
            <CardActions>
              <Button className="full-width" variant="contained" type="submit">
                Find my order
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
      <div className="search-results-card">
        <Card
          sx={{ minWidth: 275 }}
          className={data[0] == null ? "closed" : "happy"}
        >
          <CardContent>
            {!data[0] ? (
              "Loading"
            ) : (
              <div>
                <div className="order-status-header">
                  <span className="order-status-text">
                    Order {data[0].fields["PO#"]} Status
                  </span>
                  <img
                    src="/img/jme_logo.png"
                    className="order-status-logo"
                    alt="logo"
                  />
                </div>
                <div>
                  <div>
                    <Divider light />
                    <CustomizedSteppers
                      step={getStepperStatus(data[0].fields)}
                    />
                  </div>
                </div>

                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <OrderDetailsBlock
                      content={dataRenderMap.sectionOne}
                      data={data[0].fields}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <OrderDetailsBlock
                      content={dataRenderMap.sectionTwo}
                      data={data[0].fields}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <OrderDetailsBlock
                      content={dataRenderMap.sectionThree}
                      data={data[0].fields}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <OrderDetailsBlock
                      content={dataRenderMap.sectionFour}
                      data={data[0].fields}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </CardContent>
        </Card>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{" No oder found matching that id"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Please try again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className="full-width"
              variant="contained"
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
