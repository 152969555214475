import { useParams } from "react-router-dom";
import OrderSearch from "./OrderSearch";
import React from "react";
function Home() {
  let params = useParams();

  return (
    <>
      <OrderSearch order={params.order} />
    </>
  );
}
export default Home;
