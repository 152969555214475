import * as React from "react";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoPanel from "./InfoPanel";

export default function OrderDetailsBlock(props) {
  const [modalState, updateModelState] = useState(false);
  const [header, updateHeaderState] = useState("default");
  const [info, updateInfoState] = useState({});

  const onClick = (data) => {
    updateHeaderState(props.content.rows[data].name);
    updateInfoState(props.content.rows[data].details);
    updateModelState(true);
  };

  return (
    <div>
      <div className="order-detail-header">{props.content.header}</div>
      {Object.keys(props.content.rows).map((e, i) => {
        return (
          <div width="100%" key={i}>
            <div className="float-left render-name">
              <span>
                {props.content.rows[e].name}
                <IconButton
                  aria-label="delete"
                  size="small"
                  color="secondary"
                  onClick={() => onClick(e)}
                >
                  <HelpOutlineIcon fontSize="inherit" />
                </IconButton>
              </span>
            </div>
            <div className="float-left render-value">
              {!props.data ? "Loading" : <span>{props.data[e]}</span>}
            </div>
          </div>
        );
      })}
      <InfoPanel
        open={modalState}
        toggle={updateModelState}
        header={header}
        info={info}
      />
    </div>
  );
}
